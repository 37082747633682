import Axios, { type AxiosRequestConfig } from 'axios'
import type { App } from "vue"

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE,
  withCredentials: true,
})

declare module 'vue' {
  interface ComponentCustomProperties {
    $axios: typeof axios
  }
}

export default function(app: App) {
  app.config.globalProperties.$axios = axios
}

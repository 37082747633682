import { Subject } from './Subject'
import * as t from './transforms'

export default class Board {
  code!: string
  title!: string

  @t.Klass(Subject)
  subjects: Subject[] = []
}

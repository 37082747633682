import { Account } from "$frontend/models"
import BaseRequest from "./BaseRequest"
import type { AxiosResponse } from "axios"

export default {
  Get: class extends BaseRequest<Account> {
    endpoint = "/api/account"
    method = "GET"

    processResponse(response: AxiosResponse<any, any>) {
      return this.responseToObject(Account, response)
    }
  }
}

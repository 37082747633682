import { setupLayouts } from 'virtual:generated-layouts'
import type { App } from "vue"
import { createRouter, createWebHistory } from 'vue-router'
import { routes as rawRoutes } from 'vue-router/auto-routes'
import { traverse } from '$common/lib/utils'

const routes = rawRoutes.filter(it => it.meta?.namespace == "frontend")

traverse(routes, (route) => {
  if (!route.component) {
    return
  }

  route.meta = { layout: "frontend/layouts/default", ...route.meta }
})

console.log(routes)

const router = createRouter({
  history: createWebHistory(),
  routes: setupLayouts(routes),
})

export default function(app: App) {
  app.config.globalProperties.$router = router as any;
  app.use(router)
}

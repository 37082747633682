import * as requests from "$frontend/requests"
import { defineStore, getActivePinia } from 'pinia'
import { ref, type App } from 'vue'
import { type Account } from '$frontend/models'
import { AxiosError } from "axios"

export const useSessionStore = defineStore('session', () => {
  const app = (getActivePinia() as any)._a as App
  const proxy = app.config.globalProperties

  const account = ref(undefined as Account | null | undefined)

  async function prepare() {
    if (account.value !== undefined) {
      return account.value
    }

    try {
      account.value = await new requests.accounts.Get().setup(proxy).fire()
    } catch(e) {
      if (e instanceof AxiosError && e.response?.status == 401) {
        account.value = null
      } else {
        throw e
      }
    }

    return account.value
  }

  return { account, prepare }
})

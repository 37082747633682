import * as t from './transforms'

export class BilibiliInfo {
  uid!: string
  season_uid!: string
}

export class DoubanInfo {
  uid!: string
}

export class BangumiInfo {
  uid!: string
}

export class SiteInfo {
  url!: string
}

class InfosMapping {
  @t.Klass(BangumiInfo)
  bangumi!: BangumiInfo[]

  @t.Klass(DoubanInfo)
  douban!: DoubanInfo[]

  @t.Klass(BilibiliInfo)
  bilibili!: BilibiliInfo[]
}

class SitesMapping {
  [ key: string ]: SiteInfo
}

export class Subject {
  id!: number
  issue_code!: string
  title!: string
  category!: string
  tag_names!: string[]
  ranking!: number
  fever!: number
  quantum!: number
  origin_airtime_begin_on!: string

  infos_mapping!: InfosMapping
  sites_mapping!: SitesMapping
}

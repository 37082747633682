export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/index.vue'),
    /* no children */
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/animes',
    /* internal name: '/animes' */
    /* no component */
    children: [
      {
        path: '',
        name: '/animes/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/index.vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      },
      {
        path: ':id',
        name: '/animes/[id]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/[id].vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      },
      {
        path: 'search',
        name: '/animes/search',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/animes/search.vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/backend',
    /* internal name: '/backend' */
    /* no component */
    children: [
      {
        path: '',
        name: '/backend/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/index.vue'),
        /* no children */
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'acfun_infos',
        /* internal name: '/backend/acfun_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/acfun_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/acfun_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/acfun_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/acfun_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/acfun_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'animes',
        /* internal name: '/backend/animes' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/animes/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/animes/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'bang_lines',
            /* internal name: '/backend/animes/bang_lines' */
            /* no component */
            children: [
              {
                path: '',
                name: '/backend/animes/bang_lines/',
                component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/bang_lines/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'issues',
            /* internal name: '/backend/animes/issues' */
            /* no component */
            children: [
              {
                path: '',
                name: '/backend/animes/issues/',
                component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/issues/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':code',
                name: '/backend/animes/issues/[code]',
                component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/animes/issues/[code].vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'bangumi_infos',
        /* internal name: '/backend/bangumi_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/bangumi_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/bangumi_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/bangumi_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/bangumi_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bangumi_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'bilibili_infos',
        /* internal name: '/backend/bilibili_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/bilibili_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/bilibili_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/bilibili_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/bilibili_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/bilibili_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'douban_infos',
        /* internal name: '/backend/douban_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/douban_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/douban_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/douban_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/douban_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/douban_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'eiga_infos',
        /* internal name: '/backend/eiga_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/eiga_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/eiga_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/eiga_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/eiga_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/eiga_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/eiga_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'games',
        /* internal name: '/backend/games' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/games/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/games/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'bang_lines',
            /* internal name: '/backend/games/bang_lines' */
            /* no component */
            children: [
              {
                path: '',
                name: '/backend/games/bang_lines/',
                component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/bang_lines/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'issues',
            /* internal name: '/backend/games/issues' */
            /* no component */
            children: [
              {
                path: '',
                name: '/backend/games/issues/',
                component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/issues/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':code',
                name: '/backend/games/issues/[code]',
                component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/games/issues/[code].vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'iqiyi_infos',
        /* internal name: '/backend/iqiyi_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/iqiyi_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/iqiyi_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/iqiyi_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/iqiyi_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/iqiyi_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'override_infos',
        /* internal name: '/backend/override_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/override_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/override_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/override_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/override_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/override_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/override_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'pptv_infos',
        /* internal name: '/backend/pptv_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/pptv_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/pptv_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/pptv_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/pptv_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/pptv_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'qq_infos',
        /* internal name: '/backend/qq_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/qq_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/qq_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/qq_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/qq_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/qq_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'syoboi_infos',
        /* internal name: '/backend/syoboi_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/syoboi_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/syoboi_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/syoboi_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/syoboi_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/syoboi_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'titledb_infos',
        /* internal name: '/backend/titledb_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/titledb_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/titledb_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/titledb_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/titledb_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/titledb_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: 'youku_infos',
        /* internal name: '/backend/youku_infos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/backend/youku_infos/',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id',
            name: '/backend/youku_infos/[id]',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/[id].vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: ':id/edit',
            name: '/backend/youku_infos/[id].edit',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/[id].edit.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'new',
            name: '/backend/youku_infos/new',
            component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/backend/pages/youku_infos/new.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      }
    ],
    meta: {
      "namespace": "backend"
    }
  },
  {
    path: '/boards',
    /* internal name: '/boards' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/boards/[id]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/boards/[id].vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/collections',
    /* internal name: '/collections' */
    /* no component */
    children: [
      {
        path: '',
        name: '/collections/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/collections/index.vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      },
      {
        path: ':id',
        name: '/collections/[id]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/collections/[id].vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/distributions',
    /* internal name: '/distributions' */
    /* no component */
    children: [
      {
        path: ':token',
        name: '/distributions/[token]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/distributions/[token].vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/games',
    /* internal name: '/games' */
    /* no component */
    children: [
      {
        path: '',
        name: '/games/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/index.vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      },
      {
        path: ':id',
        name: '/games/[id]',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/[id].vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      },
      {
        path: 'search',
        name: '/games/search',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/games/search.vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/sign_in',
    name: '/sign_in',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/1fan/app/javascript/frontend/pages/sign_in.vue'),
    /* no children */
    meta: {
      "namespace": "frontend"
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}


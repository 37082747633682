import { Collection, CollectionDouban, CollectionsTransform } from "./Collection"

export class Account {
  email!: string

  @CollectionsTransform
  collections: Array<Collection | CollectionDouban> = []

  defaultCollection(): Collection | null {
    return this.collections.find((it) => it.default) || this.collections[0] || null
  }
}

import { Distribution } from './Distribution'
import type { Entity } from './Entity'
import { ModelIndex, ModelRepo } from './ModelRepo'
import * as t from './transforms'
import { Transform, Type } from 'class-transformer'


export class EntityRepo extends ModelRepo<Entity> {
  source_uid = new ModelIndex<string, Entity>(it => it.source_uid)

  buildIndex(entity: Entity) {
    this.source_uid.add(entity)
  }
}

export class Collection {
  token!: string
  name!: string
  syscode!: string
  default!: boolean
}

export class CollectionInfo extends Collection {
  @t.Klass(Distribution)
  distributions: Distribution[] = []
}

export class CollectionDouban extends Collection {
  state!: 'syncing' | 'synced' | 'expired'
}

export class CollectionDoubanInfo extends CollectionDouban {
  @t.Klass(Distribution)
  distributions: Distribution[] = []
}

export const CollectionsTransform = Transform(({ value }) => {
  return value?.map(json => {
    if (json.syscode == "DOUBAN") {
      return t.plainToObject(CollectionDouban, json)
    } else {
      return t.plainToObject(Collection, json)
    }
  }) ?? []
})

export const CollectionInfosTransform = Transform(({ value }) => {
  return value?.map(json => {
    if (json.syscode == "DOUBAN") {
      return t.plainToObject(CollectionDoubanInfo, json)
    } else {
      return t.plainToObject(CollectionInfo, json)
    }
  }) ?? []
})

export class CollectionFactory {
  @CollectionsTransform
  list: Array<Collection | CollectionDouban> = []

  @CollectionInfosTransform
  infoList: Array<CollectionInfo | CollectionDoubanInfo> = []
}

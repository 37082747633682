import { useSessionStore } from "$frontend/stores/session"
import type { App } from "vue"

export default function(app: App) {
  const router = app.config.globalProperties.$router
  const store = app.config.globalProperties.$store

  router.beforeEach(async (to, from) => {
    const session = useSessionStore()
    await session.prepare()
  })
}

import { Entity } from "./Entity"
import { Subject } from "./Subject"
import * as t from './transforms'

export class Distribution {
  title!: string
  token!: string
  params_json!: string
  get params() { return JSON.parse(this.params_json ?? "{}") }
}

export class DistributionItems {
  last_row_id!: string

  @t.Klass(Subject)
  subjects: Subject[] = []

  @t.Klass(Entity)
  entities: Entity[] = []
}

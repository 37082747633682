<template>
  <RouterView v-slot="{ Component }">
    <component :is="Component">
      <template v-for="(_, name) in slots" v-slot:[name]="slotData"><slot :name="name" v-bind="slotData" /></template>
    </component>
  </RouterView>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'
import { RouterView } from 'vue-router'

const slots = useSlots()

// errorCaptured: (err, vm, info) => {
//   for (const handleErrors of ctx.handleErrors) {
//     if (handleErrors(err) === false) {
//       return false
//     }
//   }
// },
</script>

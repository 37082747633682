// import md5 from 'js-md5'
import _ from 'lodash'
// import { colord } from "colord"
// import dayjs from '$frontend/lib/dayjs'
import qs from "qs"
import { plainToClassFromExist } from 'class-transformer'
// import { DATETIME_LONG_FORMAT } from '$frontend/constants'
import { toValue } from 'vue'
import dayjs from './dayjs'

const color_cache = new Map<string, string>()
// export function calcColorHex(text: string) {
//   if (!color_cache.has(text)) {
//     const hex = md5.array(text).slice(0, 3)
//     const color = colord({ r: hex[0], g: hex[1], b: hex[2] })
//     const hsl = color.toHsl()
//     const new_color = colord({ h: hsl.h, s: hsl.s, l: 10 + 0.4 * hsl.l })
//     color_cache.set(text, new_color.toHex())
//   }

//   return color_cache.get(text)
// }

// export function redirect(path: string) {
//   const origin = location.origin
//   location.href = origin + (import.meta.env.VITE_RUBY_BASE ?? '/') + _.trimStart(path, "/")
// }

export function compactObject(obj: Record<string, any>, excludeValues: any[] = [ null, undefined, "" ]) {
  return _.pickBy(obj, (it) => !excludeValues.includes(it))
}

export function queryToPlain(query) {
  const querystring = qs.stringify(query)
  return qs.parse(querystring, { ignoreQueryPrefix: true })
}

export function plainToQuery(plain, compact: boolean = false): any {
  let data = toValue(plain)
  if (compact) {
    data = compactObject(data)
  }
  const querystring = qs.stringify(data)
  return qs.parse(querystring, { depth: 0, ignoreQueryPrefix: true })
}

export function extractKeys(...args: any[]) {
  const result = [] as any[]
  for (const arg of args) {
    if (arg instanceof Array) {
      result.push(...extractKeys(...arg))
    } else if (typeof arg === "string") {
      result.push(arg)
    } else {
      result.push(..._.keys(arg))
    }
  }

  return result
}

export function toDayjs(date: Date | string | number | null | undefined) {
  if (date == null) {
    return null
  }

  return dayjs(date)
}

export function instance<T>(klass: { new(): T }, raw_data: any, options: { excludes?: any[] } = {}): T {
  const exclude_names = extractKeys(...options.excludes ?? [])
  const instance = new klass()
  const names = _(instance).keys().difference(exclude_names).value()
  const data = _.pick(raw_data, names)
  plainToClassFromExist(instance, data)
  return instance
}

export function fill<T extends object>(instance: T, raws: object[] | object, options: { compact?: boolean } = {}) {
  const names = _(instance).keys().value()

  const objects = raws instanceof Array ? raws : [ raws ]
  for (const obj of objects) {
    let data = _.pick(obj, names)
    if (options.compact) {
      data = compactObject(data)
    }

    Object.assign(instance, data)
  }

  return instance
}

export function traverse<T extends { children?: T[] }>(items: T[], callback: (route: T) => void) {
  for (const item of items) {
    callback(item)

    if (item.children) {
      traverse(item.children, callback)
    }
  }
}